import cn from 'classnames';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';

import HeaderDropdown from '@/app/ui/HeaderDropdown';
import TheFooter from '@/app/ui/TheFooter';
import Toaster from '@/components/Toaster';
import { ReactComponent as AppStoreIcon } from '@/resources/assets/icons/AppStore_Icon.svg';
import { ReactComponent as GooglePlayIcon } from '@/resources/assets/icons/Google Play_Icon.svg';
import { ReactComponent as AtmoTubeLogo } from '@/resources/assets/images/atmo-cube-logo.svg';
import { LINKS } from '@/shared/constants';
import { AuthClass } from '@/shared/helpers';
import { BaseLoader } from '@/shared/ui/loader';
import { selectIsFirstRegister } from '@/store/reducers/view';

import styles from './LayoutAuth.module.css';

function LayoutAuth() {
  const match = useMatch('/auth/sign_up');
  const isFirstRegister = useSelector(selectIsFirstRegister);
  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);

  const haveTempToken = AuthClass.checkTempToken();
  const haveToken = AuthClass.checkToken();
  const isKnownUser = haveTempToken || isLoggedIn || haveToken;

  const containerClassnames = [styles.columnContainer, isKnownUser && styles.centerContent];
  const showHeaderApps = !(match && isFirstRegister);

  return (
    <React.Fragment>
      {isKnownUser && (
        <header className={styles.header}>
          <HeaderDropdown className={styles.headerDropdown} />
        </header>
      )}
      <div className="d-flex flex-column w-100 authorization-layout">
        {!isKnownUser && (
          <div className={styles.topMenu}>
            <div className={styles.logoWrapper}>
              <AtmoTubeLogo className={styles.logo} />
            </div>
            {showHeaderApps && (
              <div className={styles.appLinks}>
                <div>
                  <a href={LINKS.appstoreLinkForBtn} target="_blank" rel="noreferrer">
                    <AppStoreIcon className={styles.appIcon} />
                  </a>
                </div>
                <div>
                  <a href={LINKS.googleplayLinkForBtn} target="_blank" rel="noreferrer">
                    <GooglePlayIcon className={styles.appIcon}/>
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={cn(containerClassnames)}>
          <div className={styles.cardBody}>
            <Suspense fallback={<BaseLoader />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
        <TheFooter />
      </div>
      <Toaster />
    </React.Fragment>
  );
}

export default LayoutAuth;
