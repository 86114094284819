import { v4 as uuidv4 } from 'uuid';

import * as actionTypes from '../actions/actionTypes';
import { getBreakpoint } from '../../util/basic';

const initialState = {
  darkMode: JSON.parse(localStorage.getItem('darkMode') || 'true'),
  toasts: [],
  breakpoint: getBreakpoint(window.innerWidth),
  defaultLoc: null,
  error: null,
  // DEPRECATED! Let's redirect to error page directly. Without additional logic
  specialError: null,
  isFileError: false,
  fetching: false,
  notificationsAmount: 0,
  isEditingNotifications: false,
  isFirstRegister: true,
};

const reducer = (state = initialState, action) => {
  const { type } = action;

  if (type.endsWith('REQUEST')) {
    return { ...state, error: null, specialError: null, fetching: true };
  }

  if (type.endsWith('SUCCESS')) {
    return { ...state, fetching: false };
  }

  switch (type) {
    case actionTypes.SET_COLOR_THEME:
      localStorage.setItem('darkMode', JSON.stringify(action.darkMode));
      return { ...state, darkMode: action.darkMode };
    case actionTypes.ADD_TOAST:
      if (
        action.hasCloseButton &&
        state.toasts.filter((toast) => toast.message === action.message).length > 0
      ) {
        return state;
      }
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: uuidv4(),
            message: action.message,
            header: action.header,
            icon: action.icon,
            hasCloseButton: action.hasCloseButton,
            identification: action.identification,
          },
        ],
      };
    case actionTypes.CHANGE_TOAST:
      const index = state.toasts.findIndex((toast) => toast.identification === action.identification);
      if (action.hasCloseButton && index >= 0) {
        const newToasts = state.toasts;
        newToasts[index].message = action.message;
        return {
          ...state,
          toasts: newToasts,
        };
      }
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: uuidv4(),
            message: action.message,
            header: action.header,
            icon: action.icon,
            hasCloseButton: action.hasCloseButton,
            identification: action.identification,
          },
        ],
      };
    case actionTypes.REMOVE_TOAST:
      return { ...state, toasts: state.toasts.slice(1) };
    case actionTypes.CLEAR_TOASTS:
      return { ...state, toasts: [] };
    case actionTypes.REMOVE_TOAST_BY_INDEX:
      const [...newToasts] = state.toasts;
      newToasts.splice(action.index, 1);
      return { ...state, toasts: newToasts };
    case actionTypes.REMOVE_TOAST_WITH_ID:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.id),
      };
    case actionTypes.REMOVE_TOAST_WITH_IDENTIFICATION:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.identification !== action.identification),
      };
    case actionTypes.SET_SCREEN_SIZE:
      return { ...state, breakpoint: action.breakpoint };
    case actionTypes.SET_DEFAULT_LOC:
      return { ...state, defaultLoc: action.loc };
    case actionTypes.SET_ERROR:
      return { ...state, error: action.error, fetching: false };
    case actionTypes.SET_SPECIAL_ERROR:
      return {
        ...state,
        specialError: action.errStatus,
        fetching: false,
        isFileError: action.isFileError,
      };
    case actionTypes.CLEAR_ERRORS:
      return { ...state, specialError: null, error: null, isFileError: false };
    case actionTypes.SET_NOTIFICATIONS_AMOUNT:
      return { ...state, notificationsAmount: action.amount };
    case actionTypes.SET_IS_EDITING_NOTIFICATIONS:
      return { ...state, isEditingNotifications: action.isEditingNotifications };
    case actionTypes.SET_IS_FIRST_REGISTER:
      return { ...state, isFirstRegister: action.isFirstRegister };
    default:
      return state;
  }
};

export default reducer;

export const selectBreakpoint = (state) => state.view.breakpoint;
export const selectDefaultLoc = (state) => state.view.defaultLoc;
export const selectFetching = (state) => state.view.fetching;
export const selectError = (state) => state.view.error;
export const selectIsEditingNotifications = (state) => state.view.isEditingNotifications;
export const selectIsFirstRegister = (state) => state.view.isFirstRegister;
export const selectToasts = (state) => state.view.toasts;
