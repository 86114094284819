// View

export const SET_COLOR_THEME = 'SET_COLOR_THEME';
export const ADD_TOAST = 'ADD_TOAST';
export const CHANGE_TOAST = 'CHANGE_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const CLEAR_TOASTS = 'CLEAR_TOASTS';
export const REMOVE_TOAST_BY_INDEX = 'REMOVE_TOAST_BY_INDEX';
export const REMOVE_TOAST_WITH_IDENTIFICATION = 'REMOVE_TOAST_WITH_IDENTIFICATION';
export const REMOVE_TOAST_WITH_ID = 'REMOVE_TOAST_WITH_ID';

export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export const SET_DEFAULT_LOC = 'SET_DEFAULT_LOC';

export const SET_ERROR = 'SET_ERROR';
export const SET_SPECIAL_ERROR = 'SET_SPECIAL_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_IS_EDITING_NOTIFICATIONS = 'SET_IS_EDITING_NOTIFICATIONS';

export const SET_NOTIFICATIONS_AMOUNT = 'SET_NOTIFICATIONS_AMOUNT';

export const SET_IS_FIRST_REGISTER = 'SET_IS_FIRST_REGISTER';

// Authorization

export const authorization = {
  LOG_IN_REQUEST: 'LOG_IN_REQUEST',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  FINAL_LOGIN_SUCCESS: 'FINAL_LOGIN_SUCCESS',

  SET_AUTHORIZATION_ERROR: 'SET_AUTHORIZATION_ERROR',
  CLEAR_AUTHORIZATION_ERROR: 'CLEAR_AUTHORIZATION_ERROR',

  CHECK_CODE_REQUEST: 'CHECK_CODE_REQUEST',
  CHECK_CODE_SUCCESS: 'CHECK_CODE_SUCCESS',

  RESEND_EMAIL_CODE_REQUEST: 'RESEND_EMAIL_CODE_REQUEST',
  RESEND_EMAIL_CODE_SUCCESS: 'RESEND_EMAIL_CODE_SUCCESS',

  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',

  LOG_OUT: 'LOG_OUT',

  CHECK_VERIFICATION_REQUEST: 'CHECK_VERIFICATION_REQUEST',
  CHECK_VERIFICATION_SUCCESS: 'CHECK_VERIFICATION_SUCCESS',

  RESEND_VERIFICATION_REQUEST: 'RESEND_VERIFICATION_REQUEST',
  RESEND_VERIFICATION_SUCCESS: 'RESEND_VERIFICATION_SUCCESS',

  VERIFY_GUARANTEED: 'VERIFY_GUARANTEED',

  FINISH_INVITATION_REQUEST: 'FINISH_INVITATION_REQUEST',
  FINISH_INVITATION_SUCCESS: 'FINISH_INVITATION_SUCCESS',
};

// Current user
export const currentUser = {
  SET_EMAIL: 'SET_EMAIL',
  GET_CURRENT_USER_INFO_REQUEST: 'GET_CURRENT_USER_INFO_REQUEST',
  GET_CURRENT_USER_INFO_SUCCESS: 'GET_CURRENT_USER_INFO_SUCCESS',

  UPDATE_CURRENT_USER_INFO: 'UPDATE_CURRENT_USER_INFO',

  CREATE_ORG_REQUEST: 'CREATE_ORG_REQUEST',
  CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',

  SET_CURRENT_ORGANIZATION_ID: 'SET_CURRENT_ORGANIZATION_ID',

  CREATE_LOC_REQUEST: 'CREATE_LOC_REQUEST',
  CREATE_LOC_SUCCESS: 'CREATE_LOC_SUCCESS',

  EDIT_ORG_REQUEST: 'EDIT_ORG_REQUEST',
  EDIT_ORG_SUCCESS: 'EDIT_ORG_SUCCESS',

  EDIT_LOC_REQUEST: 'EDIT_LOC_REQUEST',
  EDIT_LOC_SUCCESS: 'EDIT_LOC_SUCCESS',

  DELETE_LOC_REQUEST: 'DELETE_LOC_REQUEST',
  DELETE_LOC_SUCCESS: 'DELETE_LOC_SUCCESS',

  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',

  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',

  SET_HAS_DEVICES: 'SET_HAS_DEVICES',

  SET_NEW_API_KEY: 'SET_NEW_API_KEY',

  NON_AUTHORIZED_LANG_CHANGE: 'NON_AUTHORIZED_LANG_CHANGE',
};
