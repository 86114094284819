import cn from 'classnames';

import { ReactComponent as BgLeft } from '@/resources/assets/images/bg-new-left.svg';
import { ReactComponent as BgRight } from '@/resources/assets/images/bg-new-right.svg';

import styles from './ScreenWithBackground.module.css';

const ScreenWithBackground = ({className, children}) => {
  const classnames = [
    styles.root,
    className && className
  ];
  return (
    <div className={cn(classnames)}>
      {children}
      <BgLeft className={styles.left} />
      <BgRight className={styles.right} />
    </div>
  );
};

export default ScreenWithBackground;
